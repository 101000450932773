/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social-fb': {
    width: 10,
    height: 19,
    viewBox: '0 0 10 19',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.377 18.25v-7.875h2.45l.4-3.5h-2.85V5.17c0-.901.024-1.795 1.314-1.795h1.307V.873C8.998.835 7.876.75 6.74.75 4.367.75 2.882 2.2 2.882 4.863v2.012H.261v3.5h2.621v7.875h3.495z" _fill="#fff" fill-opacity=".4"/>'
  }
})
